function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
import { useAuth } from "models/Auth";
import { pipe } from "utils/helpers";
import PropTypes from "prop-types";
var Middleware = function Middleware(_ref) {
  var _ref$rules = _ref.rules,
    rules = _ref$rules === void 0 ? [] : _ref$rules,
    node = _ref.children;
  var auth = useAuth();
  rules = [].concat(rules).reverse();
  var show = function show() {
    return node;
  };
  return pipe.apply(void 0, _toConsumableArray(rules))(show)(auth);
};
Middleware.propTypes = {
  rules: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  children: PropTypes.node.isRequired
};
export default Middleware;