import { varTranEnter, varTranExit } from "./transition";
export var varRotate = function varRotate(props) {
  var durationIn = props === null || props === void 0 ? void 0 : props.durationIn;
  var durationOut = props === null || props === void 0 ? void 0 : props.durationOut;
  var easeIn = props === null || props === void 0 ? void 0 : props.easeIn;
  var easeOut = props === null || props === void 0 ? void 0 : props.easeOut;
  return {
    // IN
    "in": {
      initial: {
        opacity: 0,
        rotate: -360
      },
      animate: {
        opacity: 1,
        rotate: 0,
        transition: varTranEnter({
          durationIn: durationIn,
          easeIn: easeIn
        })
      },
      exit: {
        opacity: 0,
        rotate: -360,
        transition: varTranExit({
          durationOut: durationOut,
          easeOut: easeOut
        })
      }
    },
    // OUT
    out: {
      initial: {
        opacity: 1,
        rotate: 0
      },
      animate: {
        opacity: 0,
        rotate: -360,
        transition: varTranExit({
          durationOut: durationOut,
          easeOut: easeOut
        })
      }
    }
  };
};