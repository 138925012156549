export { default as UploadIllustration } from "./illustrations/upload";
export { default as SeoIllustration } from "./illustrations/seo";
export { default as SeverErrorIllustration } from "./illustrations/500";
export { default as TwoFactorIllustration } from "./illustrations/two_factor";
export { default as MaintenanceIllustration } from "./illustrations/maintenance";
export { default as PageNotFoundIllustration } from "./illustrations/404";
export { default as SuccessIllustration } from "./illustrations/success";
export { default as MailUnverifiedIllustration } from "./illustrations/mail_unverified";
export { default as MailVerifiedIllustration } from "./illustrations/mail_verified";
export { default as OrderCompleteIllustration } from "./illustrations/order_complete";
export { default as CountryIllustration } from "./illustrations/country";
export { default as EmptyIllustration } from "./illustrations/empty_content";
export { default as EmptyCart } from "./illustrations/empty_cart";
export { default as BookingIllustration } from "./illustrations/booking";
export { default as CheckInIllustration } from "./illustrations/checkin";
export { default as CheckOutIllustration } from "./illustrations/checkout";
export { default as SentIcon } from "./icons/sent";
export { default as DocIcon } from "./icons/doc";