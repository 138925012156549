import { createSlice } from "@reduxjs/toolkit";
var layoutState = {
  collapsedSidebar: true
};
var layout = createSlice({
  name: "layout",
  initialState: layoutState,
  reducers: {
    setCollapsedSidebar: function setCollapsedSidebar(state, action) {
      state.collapsedSidebar = action.payload;
    }
  }
});
var setCollapsedSidebar = layout.actions.setCollapsedSidebar;
export { setCollapsedSidebar };
export default layout.reducer;