import { useEffect } from "react";
import { useLocation } from "react-router-dom";
var PageRefresh = function PageRefresh() {
  var _useLocation = useLocation(),
    pathname = _useLocation.pathname;
  useEffect(function () {
    window.location.href = pathname;
  }, [pathname]);
  return null;
};
export default PageRefresh;