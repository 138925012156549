function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
var _extraReducers;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { route, thunkRequest } from "services/Http";
var cartAdapter = createEntityAdapter();
var giftcardsState = {
  cartItems: cartAdapter.getInitialState({
    error: null,
    loading: false
  })
};
var _cartAdapter$getSelec = cartAdapter.getSelectors(function (_ref) {
    var giftcards = _ref.giftcards;
    return giftcards.cartItems;
  }),
  selectCartItemIds = _cartAdapter$getSelec.selectIds,
  selectCartItems = _cartAdapter$getSelec.selectAll,
  selectCartItem = _cartAdapter$getSelec.selectById,
  selectTotalCartItems = _cartAdapter$getSelec.selectTotal;
export { selectCartItemIds, selectCartItems, selectCartItem, selectTotalCartItems };
export var updateCartItems = createAsyncThunk("giftcards/updateCartItems", function (arg, api) {
  var url = route("giftcard.cart", {
    ids: selectCartItemIds(api.getState())
  });
  return thunkRequest(api).get(url);
}, {
  condition: function condition(arg, api) {
    return !_isEmpty(selectCartItemIds(api.getState()));
  }
});
var giftcards = createSlice({
  name: "giftcards",
  initialState: giftcardsState,
  reducers: {
    addCartItem: function addCartItem(state, _ref2) {
      var item = _ref2.payload;
      state.cartItems = cartAdapter.addOne(state.cartItems, item);
    },
    updateCartItem: function updateCartItem(state, _ref3) {
      var update = _ref3.payload;
      state.cartItems = cartAdapter.updateOne(state.cartItems, update);
    },
    removeCartItem: function removeCartItem(state, _ref4) {
      var id = _ref4.payload;
      state.cartItems = cartAdapter.removeOne(state.cartItems, id);
    },
    clearCartItems: function clearCartItems(state) {
      state.cartItems = cartAdapter.removeAll(state.cartItems);
    }
  },
  extraReducers: (_extraReducers = {}, _defineProperty(_extraReducers, updateCartItems.pending, function (state) {
    state.cartItems = _objectSpread(_objectSpread({}, state.cartItems), {}, {
      error: null,
      loading: true
    });
  }), _defineProperty(_extraReducers, updateCartItems.rejected, function (state, _ref5) {
    var error = _ref5.error;
    state.cartItems = _objectSpread(_objectSpread({}, state.cartItems), {}, {
      error: error.message,
      loading: false
    });
  }), _defineProperty(_extraReducers, updateCartItems.fulfilled, function (state, _ref6) {
    var items = _ref6.payload;
    var cartItems = cartAdapter.updateMany(state.cartItems, items.map(function (item) {
      return {
        changes: _omit(item, "id"),
        id: item.id
      };
    }));
    state.cartItems = _objectSpread(_objectSpread({}, cartItems), {}, {
      error: null,
      loading: false
    });
  }), _extraReducers)
});
var _giftcards$actions = giftcards.actions,
  addCartItem = _giftcards$actions.addCartItem,
  updateCartItem = _giftcards$actions.updateCartItem,
  removeCartItem = _giftcards$actions.removeCartItem,
  clearCartItems = _giftcards$actions.clearCartItems;
export { addCartItem, updateCartItem, removeCartItem, clearCartItems };
export default giftcards.reducer;