export function responsiveFontSizes(_ref) {
  var sm = _ref.sm,
    md = _ref.md,
    lg = _ref.lg;
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm)
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md)
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg)
    }
  };
}
export function pxToRem(value) {
  return "".concat(value / 16, "rem");
}