import _useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
var useScreenType = function useScreenType() {
  var theme = useTheme();
  var isMobile = _useMediaQuery(theme.breakpoints.down("sm"));
  var isTablet = _useMediaQuery(theme.breakpoints.between("sm", "lg"));
  var isDesktop = _useMediaQuery(theme.breakpoints.up("lg"));
  return {
    isMobile: isMobile,
    isDesktop: isDesktop,
    isTablet: isTablet
  };
};
export default useScreenType;