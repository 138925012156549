import _cloneDeep from "lodash/cloneDeep";
import { configureStore } from "@reduxjs/toolkit";
export function createStore(storeReducer, initialState) {
  return configureStore({
    devTools: process.env.NODE_ENV !== "production",
    reducer: storeReducer,
    preloadedState: initialState
  });
}
export function normalizeResource(state) {
  var normalized = _cloneDeep(state);
  for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    params[_key - 1] = arguments[_key];
  }
  params.forEach(function (key) {
    normalized[key] = {
      loading: false,
      data: state[key],
      error: null
    };
  });
  return normalized;
}