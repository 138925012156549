import { useEffect } from "react";
import { useLocation } from "react-router-dom";
var ScrollToTop = function ScrollToTop() {
  var _useLocation = useLocation(),
    pathname = _useLocation.pathname;
  useEffect(function () {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
export default ScrollToTop;