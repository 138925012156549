export var varTranHover = function varTranHover(props) {
  var duration = (props === null || props === void 0 ? void 0 : props.duration) || 0.32;
  var ease = (props === null || props === void 0 ? void 0 : props.ease) || [0.43, 0.13, 0.23, 0.96];
  return {
    duration: duration,
    ease: ease
  };
};
export var varTranEnter = function varTranEnter(props) {
  var duration = (props === null || props === void 0 ? void 0 : props.durationIn) || 0.64;
  var ease = (props === null || props === void 0 ? void 0 : props.easeIn) || [0.43, 0.13, 0.23, 0.96];
  return {
    duration: duration,
    ease: ease
  };
};
export var varTranExit = function varTranExit(props) {
  var duration = (props === null || props === void 0 ? void 0 : props.durationOut) || 0.48;
  var ease = (props === null || props === void 0 ? void 0 : props.easeOut) || [0.43, 0.13, 0.23, 0.96];
  return {
    duration: duration,
    ease: ease
  };
};