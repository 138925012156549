function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import palette from "../palette";
export var colorPresets = [// DEFAULT
_objectSpread({
  name: "default"
}, palette.light.primary),
// PURPLE
{
  name: "purple",
  lighter: "#EBD6FD",
  light: "#B985F4",
  main: "#7635dc",
  dark: "#431A9E",
  darker: "#200A69",
  contrastText: "#fff"
},
// CYAN
{
  name: "cyan",
  lighter: "#D1FFFC",
  light: "#76F2FF",
  main: "#1CCAFF",
  dark: "#0E77B7",
  darker: "#053D7A",
  contrastText: palette.light.grey[800]
},
// BLUE
{
  name: "blue",
  lighter: "#D1E9FC",
  light: "#76B0F1",
  main: "#2065D1",
  dark: "#103996",
  darker: "#061B64",
  contrastText: "#fff"
},
// ORANGE
{
  name: "orange",
  lighter: "#FEF4D4",
  light: "#FED680",
  main: "#fda92d",
  dark: "#B66816",
  darker: "#793908",
  contrastText: palette.light.grey[800]
},
// RED
{
  name: "red",
  lighter: "#FFE3D5",
  light: "#FFC1AC",
  main: "#FF3030",
  dark: "#B71833",
  darker: "#7A0930",
  contrastText: "#fff"
}];
export var defaultPreset = colorPresets[0];
export var purplePreset = colorPresets[1];
export var cyanPreset = colorPresets[2];
export var bluePreset = colorPresets[3];
export var orangePreset = colorPresets[4];
export var redPreset = colorPresets[5];
export default function getColorPresets(key) {
  switch (key) {
    case "purple":
      return purplePreset;
    case "cyan":
      return cyanPreset;
    case "blue":
      return bluePreset;
    case "orange":
      return orangePreset;
    case "red":
      return redPreset;
    default:
      return defaultPreset;
  }
}