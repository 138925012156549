import { varTranEnter, varTranExit } from "./transition";
export var varScale = function varScale(props) {
  var durationIn = props === null || props === void 0 ? void 0 : props.durationIn;
  var durationOut = props === null || props === void 0 ? void 0 : props.durationOut;
  var easeIn = props === null || props === void 0 ? void 0 : props.easeIn;
  var easeOut = props === null || props === void 0 ? void 0 : props.easeOut;
  return {
    // IN
    inX: {
      initial: {
        scaleX: 0,
        opacity: 0
      },
      animate: {
        scaleX: 1,
        opacity: 1,
        transition: varTranEnter({
          durationIn: durationIn,
          easeIn: easeIn
        })
      },
      exit: {
        scaleX: 0,
        opacity: 0,
        transition: varTranExit({
          durationOut: durationOut,
          easeOut: easeOut
        })
      }
    },
    inY: {
      initial: {
        scaleY: 0,
        opacity: 0
      },
      animate: {
        scaleY: 1,
        opacity: 1,
        transition: varTranEnter({
          durationIn: durationIn,
          easeIn: easeIn
        })
      },
      exit: {
        scaleY: 0,
        opacity: 0,
        transition: varTranExit({
          durationOut: durationOut,
          easeOut: easeOut
        })
      }
    },
    // OUT
    outX: {
      initial: {
        scaleX: 1,
        opacity: 1
      },
      animate: {
        scaleX: 0,
        opacity: 0,
        transition: varTranEnter({
          durationIn: durationIn,
          easeIn: easeIn
        })
      }
    },
    outY: {
      initial: {
        scaleY: 1,
        opacity: 1
      },
      animate: {
        scaleY: 0,
        opacity: 0,
        transition: varTranEnter({
          durationIn: durationIn,
          easeIn: easeIn
        })
      }
    }
  };
};