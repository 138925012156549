function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _assign from "lodash/assign";
var _extraReducers;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { route, thunkRequest } from "services/Http";
import context from "core/context";
var settingsState = {
  layout: "default",
  locales: {},
  modules: {},
  installer: {
    display: false,
    license: false
  },
  windowSize: {
    width: 1200,
    height: 900
  },
  locale: {
    error: null,
    loading: false,
    data: {
      locale: "en",
      messages: {}
    }
  },
  baseCurrency: "USD",
  recaptcha: {
    enable: false,
    sitekey: "",
    size: "normal"
  },
  theme: {
    mode: "dark",
    direction: "ltr",
    color: "orange"
  },
  brand: {
    favicon_url: null,
    logo_url: null,
    support_url: null,
    terms_url: null,
    policy_url: null
  }
};
export var initSettingsState = function initSettingsState() {
  return _assign({}, settingsState, context.settings);
};

/**
 * BEGIN: Async Actions
 */
export var fetchLocale = createAsyncThunk("settings/fetchLocale", function (arg, api) {
  return thunkRequest(api).post(route("locale.get"));
});
export var updateLocale = createAsyncThunk("settings/updateLocale", function (locale, api) {
  return thunkRequest(api).post(route("locale.set"), {
    locale: locale
  });
});
export var fetchBrand = createAsyncThunk("settings/fetchBrand", function (arg, api) {
  return thunkRequest(api).get(route("settings.brand"));
});
export var fetchTheme = createAsyncThunk("settings/fetchTheme", function (arg, api) {
  return thunkRequest(api).get(route("settings.theme"));
});

/**
 * END: Async Actions
 */

var settings = createSlice({
  name: "settings",
  initialState: settingsState,
  reducers: {
    setWindowSize: function setWindowSize(state, action) {
      state.windowSize = action.payload;
    },
    setGridSpacing: function setGridSpacing(state, action) {
      state.gridSpacing = action.payload;
    },
    setRecaptcha: function setRecaptcha(state, action) {
      state.recaptcha = action.payload;
    },
    setLocaleData: function setLocaleData(state, action) {
      state.localeData = action.payload;
    }
  },
  extraReducers: (_extraReducers = {}, _defineProperty(_extraReducers, fetchLocale.pending, function (state) {
    state.locale = _objectSpread(_objectSpread({}, state.locale), {}, {
      error: null,
      loading: true
    });
  }), _defineProperty(_extraReducers, fetchLocale.rejected, function (state, action) {
    state.locale = _objectSpread(_objectSpread({}, state.locale), {}, {
      error: action.error.message,
      loading: false
    });
  }), _defineProperty(_extraReducers, fetchLocale.fulfilled, function (state, action) {
    state.locale = _objectSpread(_objectSpread({}, state.locale), {}, {
      error: null,
      data: action.payload,
      loading: false
    });
  }), _defineProperty(_extraReducers, updateLocale.pending, function (state) {
    state.locale = _objectSpread(_objectSpread({}, state.locale), {}, {
      error: null,
      loading: true
    });
  }), _defineProperty(_extraReducers, updateLocale.rejected, function (state, action) {
    state.locale = _objectSpread(_objectSpread({}, state.locale), {}, {
      error: action.error.message,
      loading: false
    });
  }), _defineProperty(_extraReducers, updateLocale.fulfilled, function (state, action) {
    state.locale = _objectSpread(_objectSpread({}, state.locale), {}, {
      error: null,
      data: action.payload,
      loading: false
    });
  }), _defineProperty(_extraReducers, fetchBrand.fulfilled, function (state, action) {
    state.brand = action.payload;
  }), _defineProperty(_extraReducers, fetchTheme.fulfilled, function (state, action) {
    state.theme = action.payload;
  }), _extraReducers)
});
var _settings$actions = settings.actions,
  setWindowSize = _settings$actions.setWindowSize,
  setGridSpacing = _settings$actions.setGridSpacing,
  setRecaptcha = _settings$actions.setRecaptcha,
  setLocaleData = _settings$actions.setLocaleData;
export { setWindowSize, setGridSpacing, setRecaptcha, setLocaleData };
export default settings.reducer;