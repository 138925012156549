import _forEach from "lodash/forEach";
import { defineMessages } from "react-intl";
export { getValidationMessages } from "./validationMessage";
var messages = defineMessages({
  invalidPhone: {
    id: "70f8194bb87812258ad0",
    defaultMessage: "{field} is an invalid phone number"
  },
  passwordUnmatched: {
    id: "6be188e043b82427d55c",
    defaultMessage: "password does not match."
  }
});
export function normalizeDates(values) {
  for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    params[_key - 1] = arguments[_key];
  }
  _forEach(params, function (key) {
    var _values$key;
    if ((_values$key = values[key]) !== null && _values$key !== void 0 && _values$key.isValid()) {
      values[key] = values[key].utc().format();
    } else {
      values[key] = undefined;
    }
  });
}
export function passwordConfirmation(intl) {
  var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "password";
  return function (form) {
    return {
      validator: function validator(rule, value) {
        if (value && form.getFieldValue(field) !== value) {
          var message = intl.formatMessage(messages.passwordUnmatched);
          return Promise.reject(new Error(message));
        }
        return Promise.resolve();
      }
    };
  };
}