function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { pxToRem, responsiveFontSizes } from "./utils/getFontValue";
var FONT_PRIMARY = "Public Sans, sans-serif";
var typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: _objectSpread({
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    letterSpacing: 2
  }, responsiveFontSizes({
    sm: 52,
    md: 58,
    lg: 64
  })),
  h2: _objectSpread({
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32)
  }, responsiveFontSizes({
    sm: 40,
    md: 44,
    lg: 48
  })),
  h3: _objectSpread({
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24)
  }, responsiveFontSizes({
    sm: 26,
    md: 30,
    lg: 32
  })),
  h4: _objectSpread({
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20)
  }, responsiveFontSizes({
    sm: 20,
    md: 24,
    lg: 24
  })),
  h5: _objectSpread({
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18)
  }, responsiveFontSizes({
    sm: 19,
    md: 20,
    lg: 20
  })),
  h6: _objectSpread({
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17)
  }, responsiveFontSizes({
    sm: 18,
    md: 18,
    lg: 18
  })),
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12)
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: "uppercase"
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "capitalize"
  }
};
export default typography;