function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _assign from "lodash/assign";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { route, thunkRequest } from "services/Http";
import context from "core/context";
var authState = {
  user: null,
  credential: "email",
  userSetup: false
};
export var initAuthState = function initAuthState() {
  return _assign({}, authState, context.auth);
};
export var fetchUser = createAsyncThunk("auth/fetchUser", function (arg, api) {
  return thunkRequest(api).get(route("user.get"));
});
var auth = createSlice({
  name: "auth",
  initialState: authState,
  extraReducers: _defineProperty({}, fetchUser.fulfilled, function (state, action) {
    state.user = action.payload;
  })
});
export default auth.reducer;