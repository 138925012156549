import { alpha } from "@mui/material/styles";
export default function Pagination(theme) {
  return {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            fontWeight: theme.typography.fontWeightBold
          }
        },
        textPrimary: {
          "&.Mui-selected": {
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
            "&:hover, &.Mui-focusVisible": {
              backgroundColor: "".concat(alpha(theme.palette.primary.main, 0.24), " !important")
            }
          }
        },
        outlined: {
          border: "1px solid ".concat(theme.palette.grey[50032])
        },
        outlinedPrimary: {
          "&.Mui-selected": {
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
            border: "1px solid ".concat(alpha(theme.palette.primary.main, 0.24))
          }
        }
      }
    }
  };
}