import { defineMessages } from "react-intl";
var messages = defineMessages({
  "default": {
    id: "d03f02b189f3f28f4847",
    defaultMessage: "Validation error on field ${name}"
  },
  required: {
    id: "2314b081891b8b1c54ed",
    defaultMessage: "${name} is required"
  },
  "enum": {
    id: "72d63c0ea6f3479683d7",
    defaultMessage: "${name} must be one of [${enum}]"
  },
  whitespace: {
    id: "167937c9364bee1166a8",
    defaultMessage: "${name} cannot be empty"
  },
  "date.format": {
    id: "af4d9ff0659dd399bc56",
    defaultMessage: "${name} is invalid for format date"
  },
  "date.parse": {
    id: "aec7abfa24382e8daaa8",
    defaultMessage: "${name} could not be parsed as date"
  },
  "date.invalid": {
    id: "c1621611fe3568b74c40",
    defaultMessage: "${name} is invalid date"
  },
  "types.template": {
    id: "66b958de10e4b38c703c",
    defaultMessage: "${name} is not a valid ${type}"
  },
  "string.len": {
    id: "bf53eacaaa46ea28058d",
    defaultMessage: "${name} must be exactly ${len} characters"
  },
  "string.min": {
    id: "d2c1c932a02083078f59",
    defaultMessage: "${name} must be at least ${min} characters"
  },
  "string.max": {
    id: "6751099f60b073101e10",
    defaultMessage: "${name} cannot be longer than ${max} characters"
  },
  "string.range": {
    id: "8fd29f47cf5f43b40560",
    defaultMessage: "${name} must be between ${min} and ${max} characters"
  },
  "number.len": {
    id: "d7d02c453846c989e87e",
    defaultMessage: "${name} must equal ${len}"
  },
  "number.min": {
    id: "fdfb28a1e526d30aa083",
    defaultMessage: "${name} cannot be less than ${min}"
  },
  "number.max": {
    id: "37136e1cad4da9b62d02",
    defaultMessage: "${name} cannot be greater than ${max}"
  },
  "number.range": {
    id: "432fd80f3a84946ce7d7",
    defaultMessage: "${name} must be between ${min} and ${max}"
  },
  "array.len": {
    id: "2611e6cad8987a42f784",
    defaultMessage: "${name} must be exactly ${len} in length"
  },
  "array.min": {
    id: "cfb168cc776bf4ac7f9e",
    defaultMessage: "${name} cannot be less than ${min} in length"
  },
  "array.max": {
    id: "adaa8ac24d5cb57c93c8",
    defaultMessage: "${name} cannot be greater than ${max} in length"
  },
  "array.range": {
    id: "0bdfb12ce4b62f018f90",
    defaultMessage: "${name} must be between ${min} and ${max} in length"
  },
  "pattern.mismatch": {
    id: "37e3e389008b1b12b6e3",
    defaultMessage: "${name} does not match pattern ${pattern}"
  }
});
var params = {
  name: "{name}",
  type: "{type}",
  "enum": "{enum}",
  len: "{len}",
  min: "{min}",
  max: "{max}",
  pattern: "{pattern}"
};
export function getValidationMessages(intl) {
  return {
    "default": intl.formatMessage(messages["default"], {
      name: params.name
    }),
    required: intl.formatMessage(messages["required"], {
      name: params.name
    }),
    "enum": intl.formatMessage(messages["enum"], {
      name: params.name,
      "enum": params["enum"]
    }),
    whitespace: intl.formatMessage(messages["whitespace"], {
      name: params.name
    }),
    date: {
      format: intl.formatMessage(messages["date.format"], {
        name: params.name
      }),
      parse: intl.formatMessage(messages["date.parse"], {
        name: params.name
      }),
      invalid: intl.formatMessage(messages["date.invalid"], {
        name: params.name
      })
    },
    types: {
      string: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      method: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      array: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      object: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      number: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      date: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      "boolean": intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      integer: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      "float": intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      regexp: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      email: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      url: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      }),
      hex: intl.formatMessage(messages["types.template"], {
        name: params.name,
        type: params.type
      })
    },
    string: {
      len: intl.formatMessage(messages["string.len"], {
        name: params.name,
        len: params.len
      }),
      min: intl.formatMessage(messages["string.min"], {
        name: params.name,
        min: params.min
      }),
      max: intl.formatMessage(messages["string.max"], {
        name: params.name,
        max: params.max
      }),
      range: intl.formatMessage(messages["string.range"], {
        name: params.name,
        min: params.min,
        max: params.max
      })
    },
    number: {
      len: intl.formatMessage(messages["number.len"], {
        name: params.name,
        len: params.len
      }),
      min: intl.formatMessage(messages["number.min"], {
        name: params.name,
        min: params.min
      }),
      max: intl.formatMessage(messages["number.max"], {
        name: params.name,
        max: params.max
      }),
      range: intl.formatMessage(messages["number.range"], {
        name: params.name,
        min: params.min,
        max: params.max
      })
    },
    array: {
      len: intl.formatMessage(messages["array.len"], {
        name: params.name,
        len: params.len
      }),
      min: intl.formatMessage(messages["array.min"], {
        name: params.name,
        min: params.min
      }),
      max: intl.formatMessage(messages["array.max"], {
        name: params.name,
        max: params.max
      }),
      range: intl.formatMessage(messages["array.range"], {
        name: params.name,
        min: params.min,
        max: params.max
      })
    },
    pattern: {
      mismatch: intl.formatMessage(messages["pattern.mismatch"], {
        name: params.name,
        pattern: params.pattern
      })
    }
  };
}