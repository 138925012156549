export function getFieldId(name, formName) {
  if (name.length) {
    var id = name.join("_");
    if (formName) {
      return "".concat(formName, "_").concat(id);
    } else {
      return id;
    }
  } else {
    return undefined;
  }
}