function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable no-undef */
export default function DataGrid(theme) {
  return {
    MuiDataGrid: {
      defaultProps: {
        nonce: __webpack_nonce__
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          border: "1px solid transparent",
          "& .MuiTablePagination-root": {
            borderTop: 0
          },
          "& .MuiDataGrid-toolbarContainer": {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.neutral,
            "& .MuiButton-root": {
              marginRight: theme.spacing(1.5),
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: theme.palette.action.hover
              }
            }
          },
          "& .MuiDataGrid-cell, .MuiDataGrid-columnsContainer": {
            borderBottom: "1px solid ".concat(theme.palette.divider)
          },
          "& .MuiDataGrid-columnSeparator": {
            color: theme.palette.divider
          },
          '& .MuiDataGrid-columnHeader[data-field="__check__"]': {
            padding: 0
          },
          "& .pointer": {
            cursor: "pointer"
          }
        },
        columnHeader: {
          "&:focus, &:focus-within, &:focus-visible": {
            outline: "none"
          }
        },
        cell: {
          "&:focus, &:focus-within, &:focus-visible": {
            outline: "none"
          }
        }
      }
    },
    MuiGridMenu: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-gridMenuList": {
            boxShadow: theme.customShadows.z20,
            borderRadius: theme.shape.borderRadius
          },
          "& .MuiMenuItem-root": _objectSpread({}, theme.typography.body2)
        }
      }
    },
    MuiGridFilterForm: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5, 0),
          "& .MuiFormControl-root": {
            margin: theme.spacing(0, 0.5)
          },
          "& .MuiInput-root": {
            marginTop: theme.spacing(3),
            "&::before, &::after": {
              display: "none"
            },
            "& .MuiNativeSelect-select, .MuiInput-input": _objectSpread(_objectSpread({}, theme.typography.body2), {}, {
              padding: theme.spacing(0.75, 1),
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.background.neutral
            }),
            "& .MuiSvgIcon-root": {
              right: 4
            }
          }
        }
      }
    },
    MuiGridPanelFooter: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
          justifyContent: "flex-end",
          "& .MuiButton-root": {
            "&:first-of-type": {
              marginRight: theme.spacing(1.5),
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: theme.palette.action.hover
              }
            },
            "&:last-of-type": {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark
              }
            }
          }
        }
      }
    }
  };
}